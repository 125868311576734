import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';
import Logo from '../images/gse-help-logo.svg';
import AppDrawer from './AppDrawer';
import { useNavigate } from 'react-router-dom';

export default function StyledAppBar(){
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
 
    const toggleDrawer = () => {
        setOpen(prev => !prev);
    }
    
    return (
        <>
            <Box sx={{ width:"100%", height:"5em", zIndex:"999"}}>
                <AppBar position="static" elevation = {0} sx={{background: "linear-gradient(0deg, rgba(0,65,145,1) 0%, rgba(10,75,155,1) 100%)"}}>            
                    <Toolbar variant="dense" sx={{height:"5em"}}>
                        <Stack direction="row" spacing={5} alignItems="center" justifyContent="flex-start">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => toggleDrawer()}
                                >
                                    <MenuIcon sx={{scale:"1.5", color:"#fff !important"}}/>
                                </IconButton>
                        </Stack> 
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" flexGrow="1" sx={{marginLeft:{xs:"-1em", lg:"-3em"}}}>
                            <img src={Logo} alt="Logo" style={{maxWidth:"300px", width:"75%",  cursor:"pointer"}} onClick={() => navigate("/")}/>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
            <AppDrawer open={open} toggleDrawer={toggleDrawer}/>
        </>
    )
}