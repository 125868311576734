import * as React from 'react';
import { Box, Typography } from '@mui/material';


export default function Footer(){
    return (
        <Box sx={{ width:"100%", height:"1em", padding:"2em 0", justifyContent:"center", alignItems:"center"}}>
            <Typography variant="body2" sx={{textAlign:"center", color:"#999"}}>
                    &copy;{new Date().getFullYear()} Powertrain Control Solutions, LLC. All Rights Reserved.
            </Typography>
        </Box>
    )
}