import React, { useState, useEffect, Suspense, useContext } from "react";
import SearchBar from '../components/SearchBar';
import api from '../services/api';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Stack, Typography, Button, Select, MenuItem } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ScrollTable from "../components/ScrollTable";
import LoadingOverlay from "../components/LoadingOverlay";
import { getSession, logout } from "../services/cognito";
import StyledAppBar from "../components/StyledAppBar";
import Footer from "../components/Footer";
import ContactBar from "../components/ContactBar";

const textronColumns = [
    {
        label: 'VIN',
        dataKey: 'VIN'
    },
    {
        label: 'Date',
        dataKey: 'Date_Time'
    },
    {
        label: 'BATT Serial 1',
        dataKey: 'BATT_SERIAL1'
    },
    {
        label: 'BATT Serial 2',
        dataKey: 'BATT_SERIAL2'
    },
    {
        label: 'Total Programming Success',
        dataKey: 'All_Programmed'
    },
    {
        label: 'EPM Firmware',
        dataKey: 'EPM_Firmware'
    },
    {
        label: 'EPM Calibration',
        dataKey: 'EPM_Calibration'
    },
    {
        label: 'EPM Program Success',
        dataKey: 'EPM_Program_Success'
    },
    {
        label: 'VICM Archive',
        dataKey: 'VICM_Archive'
    },
    {
        label: 'VICM Files Installed',
        dataKey: 'VICM_Files_Installed'
    },
    {
        label: 'VICM Program Success',
        dataKey: 'VICM_Program_Success'
    },
    {
        label: 'SPIM Archive',
        dataKey: 'SPIM_Archive'
    },
    {
        label: 'SPIM Files Installed',
        dataKey: 'SPIM_Files_Installed'
    },
    {
        label: 'SPIM Program Success',
        dataKey: 'SPIM_Program_Success'
    },
    {
        label: 'PLCM Archive',
        dataKey: 'PLCM_Archive'
    },
    {
        label: 'PLCM Files Installed',
        dataKey: 'PLCM_Files_Installed'
    },
    {
        label: 'PLCM Program Success',
        dataKey: 'PLCM_Program_Success'
    }
];

const volconColumns = [
    {
        label: 'VIN',
        dataKey: 'VIN'
    },
    {
        label: 'Date',
        dataKey: 'Date_Time'
    },
    {
        label: 'BATT Serial 1',
        dataKey: 'BATT_SERIAL1'
    },
    {
        label: 'Total Programming Success',
        dataKey: 'All_Programmed'
    },
    {
        label: 'EPM Firmware',
        dataKey: 'EPM_Firmware'
    },
    {
        label: 'EPM Calibration',
        dataKey: 'EPM_Calibration'
    },
    {
        label: 'EPM Program Success',
        dataKey: 'EPM_Program_Success'
    },
    {
        label: 'VITM Archive',
        dataKey: 'VITM_Archive'
    },
    {
        label: 'VITM Files Installed',
        dataKey: 'VITM_Files_Installed'
    },
    {
        label: 'VITM Program Success',
        dataKey: 'VITM_Program_Success'
    },
    {
        label: 'VICM Archive',
        dataKey: 'VICM_Archive'
    },
    {
        label: 'VICM Files Installed',
        dataKey: 'VICM_Files_Installed'
    },
    {
        label: 'VICM Program Success',
        dataKey: 'VICM_Program_Success'
    },
    {
        label: 'SPIM Archive',
        dataKey: 'SPIM_Archive'
    },
    {
        label: 'SPIM Files Installed',
        dataKey: 'SPIM_Files_Installed'
    },
    {
        label: 'SPIM Program Success',
        dataKey: 'SPIM_Program_Success'
    },
    {
        label: 'ONSTAR Archive',
        dataKey: 'ONSTAR_Archive'
    },
    {
        label: 'ONSTAR Files Installed',
        dataKey: 'ONSTAR_Files_Installed'
    },
    {
        label: 'ONSTAR Program Success',
        dataKey: 'ONSTAR_Program_Success'
    },
    {
        label: 'ONSTAR IMEI',
        dataKey: 'ONSTAR_IMEI'
    },
    {
        label: 'ONSTAR STID',
        dataKey: 'ONSTAR_STID'
    },
    {
        label: 'ONSTAR VIN',
        dataKey: 'ONSTAR_VIN'
    },
    {
        label: 'APM Firmware',
        dataKey: 'APM_Firmware'
    },
    {
        label: 'APM Calibration',
        dataKey: 'APM_Calibration'
    },
    {
        label: 'APM Program Success',
        dataKey: 'APM_Program_Success'
    },
    {
        label: 'DCM Front Firmware',
        dataKey: 'DCM_Front_Firmware'
    },
    {
        label: 'DCM Front Calibration',
        dataKey: 'DCM_Front_Calibration'
    },
    {
        label: 'DCM Front Program Success',
        dataKey: 'DCM_Front_Program_Success'
    },
    {
        label: 'DCM Rear Firmware',
        dataKey: 'DCM_Rear_Firmware'
    },
    {
        label: 'DCM Rear Calibration',
        dataKey: 'DCM_Rear_Calibration'
    },
    {
        label: 'DCM Rear Program Success',
        dataKey: 'DCM_Rear_Program_Success'
    }
    
]

const orgList = [
    {
        label: 'Textron',
        datakey: 'textron'
    },
    {
        label: "Volcon",
        datakey: "volcon"
    }
]

export default function Admin() {
    const navigate = useNavigate();
    const [org, setOrg] = useState("textron");
    const [showOptions, setShowOptions] = useState(false);
    const [columns, setColumns] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [results, setResults] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(true);

    const goToSettings = () => {
        navigate('/settings');
    }

    const convertBools = (data) => {
        var newData = [];
        for (let obj of data) {
            var newObj = {};
            for (const [key, value] of Object.entries(obj)) {
                if (typeof (value) === "boolean") {
                    if (value) {
                        newObj[key] = "Success";
                    }
                    else {
                        newObj[key] = "Failure";
                    }
                }
                else {
                    newObj[key] = value;
                }
            }
            newData.push(newObj);
        }
        return newData;
    }

    const fetchData = async (orga) => {

        getSession().then(async (user)=>{
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    "Authorization": "Bearer " + user.accessToken.jwtToken
                }
            };
            var response = await api.get('/pcssoftware/' + orga, config)
            if (response.status === 200){
                var data = convertBools(response.data);
                setResults(data);
                switch (orga) {
                    case "volcon":
                        setColumns(volconColumns)
                        break;
                    default:
                        setColumns(textronColumns);
                        break;
                }
                setSearchResults(data);
                setLoading(false);
            }
            else {
                logout();
                navigate('/admin/login');
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const handleSelect = (e) => {
        setOrg(e.target.value);
        setLoading(true);
        fetchData(e.target.value);
    }

    useEffect(() => {
        getSession().then((user) => {
            setOrg(user.organization);
            var orga = user.organization
            if (user.organization === "pcs") {
                setShowOptions(true);            
                orga = org;
            }
            fetchData(orga);
        })
        .catch((error) => {
            logout();
            navigate('/admin/login')
        });

    }, [refresh]);

    return (
        <Suspense fallback={<CircularProgress />}>
            {loading && <LoadingOverlay />}
            <StyledAppBar />
            <ContactBar/>
            <Box id="viewWrapper">
                
                <Stack className="content" >

                    <Stack className="titleWrapper" direction="row" justifyContent="space-between">
                        <Typography variant="h3" sx={{color:"#999"}}>Admin Viewer</Typography>
                        {/* <SettingsIcon onClick={goToSettings}/> */}
                    </Stack>
                    
                    <Stack className="toolbar" direction="row" spacing={3} justifyContent = "flex-start" alignItems="center" >
                       
                        {showOptions
                            &&
                            <Select
                                id="selectOrg"
                                defaultValue={'textron'}
                                onChange={handleSelect}
                                label="Select Organization"
                                
                            >
                                {orgList.map(({ label, datakey }) => {
                                    return (
                                        <MenuItem key={datakey} value={datakey} label={label}>{label}</MenuItem>
                                    )
                                })}
                            </Select>

                            }
                        <SearchBar placeholder="Search VIN..." results={results} setSearchResults={setSearchResults} searchKeys={['VIN']} />
                       
                    </Stack>
                    <ScrollTable columns={columns} rows={searchResults} />
                </Stack>
                
            </Box>
            <Footer />
        </Suspense>
    )
}