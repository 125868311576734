import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, TextField, Button, Typography, Alert } from "@mui/material";
import { authenticate, passwordChallenge, getSession } from "../services/cognito";
import StyledAppBar from "../components/StyledAppBar";
import ContactBar from "../components/ContactBar";
import LoadingOverlay from "../components/LoadingOverlay";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Admin Login")
  const [email, setEmail] = useState("")
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState(false)
  const [msg, setMsg] = useState("");
  const [password, setPassword] = useState("")
  const [passwordNew, setPasswordNew] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [showPasswordChange, setShowPasswordChange] = useState(false);

  const handleKeyDown = (e) => {
    setAlertVisible(false)
    if (e.key === 'Enter') {
      submitForm();
    }
  }

  const submitForm = (e) => {
    if (showPasswordChange) {
      if (passwordNew !== passwordConfirm){
        setAlertVisible(true);
        setAlertSeverity("error");
        setMsg("Passwords do not match.");
        return;
      } 
      passwordChallenge(email, passwordNew).then(() => {
        setShowPasswordChange(false);
        setAlertVisible(true);
        setAlertSeverity("success");
        setMsg("Password changed successfully.");
        setTitle("Admin Login");
      }).catch(()=>{
        setAlertVisible(true);
        setAlertSeverity("error");
        setMsg("Password requires:\n-At least 8 characters\n-At least 1 Special character\n-At least 1 Uppercase character\n-At least 1 Lowercase character\n-At least 1 Number");
      });

    } else {
      if (email === "" || password === ""){
        setMsg("Please enter email and password.");
        setAlertSeverity("error");
        setAlertVisible(true);
        return;

      } 
      authenticate(email, password).then(data => {
        console.log("Logged in!", data);
        if (data.newPasswordRequired) {
          setShowPasswordChange(true);
          setTitle("Change Password");
          setPassword("");
        }
        else {
          navigate('/admin')
        }
      }).catch((err) =>{
        setMsg("Username or password incorrect.");
        setAlertSeverity("error");
        setAlertVisible(true);
      });
    }
  };

  useEffect(() => {

    getSession().then(({ user }) => {
      navigate('/admin');
    })
    .catch((err) => {setLoading(false); })
    
  }, []);
  
  return (
    <>
      <StyledAppBar />
      <ContactBar />

      <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        
        {loading 

          ? <LoadingOverlay />
        
          : <Stack direction="column" spacing={3} sx={{ background: "transparent", borderRadius: "5px", padding: "3em", minWidth: "350px"}}>
            <Typography variant="h5" >{title}</Typography>
            {alertVisible? <Alert severity={alertSeverity} variant="filled" sx={{alignItems:"center", color:"#fff !important", whiteSpace:'pre-line'}}>{msg} </Alert> : <></>}
            {showPasswordChange
              ?
              <>
                <TextField
                  id="passwordNew"
                  label="New Password"
                  type="password"
                  value={passwordNew}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setPasswordNew(e.target.value)}
                />
                <TextField
                  id="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  value={passwordConfirm}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </>
              :
              <>
                <TextField
                  id="username"
                  label="Username"
                  value={email}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </>
            }
            <Button variant="contained" disableElevation sx={{ fontWeight: "bold", padding: "1em" }} onClick={submitForm}>Sign In</Button>
          </Stack>
        }
      </Box>
    </>
  );
}

