import * as React from 'react';
import { Typography, Stack, ListItem, ListItemButton, ListItemText, Divider, List, Drawer, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout, isLoggedIn } from '../services/cognito';


export default function AppDrawer({open, toggleDrawer}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  }

  const DrawerList = (
    <Box sx={{ width: "auto", padding:"40px", display:"flex", flexGrow:"1", flexDirection:"column"}} role="presentation" onClick={() => toggleDrawer()}>
      <List>
        <ListItem key="title" disablePadding>     
          <Typography component="div" variant="subtitle" sx={{fontWeight:"bold", padding:"1em 0", textAlign:"center", width:"100%"}}>GSE HELP MENU</Typography>
        </ListItem>

        <Divider sx={{background:"#555", marginBottom:"20px"}}/>

        <ListItem key="admin" sx={{padding:"5px 0"}}>
          <ListItemButton sx={{"&:hover": {background:'#333'}, padding:"5px"}} onClick={() => navigate("/admin/login")}>
            <ListItemText primary="Admin Viewer" />
          </ListItemButton>
        </ListItem>    

        <ListItem key="bev2" sx={{padding:"5px 0"}}>
          <ListItemButton sx={{"&:hover": {background:'#333'}, padding:"5px"}} onClick={() => navigate("/ev")}>
            <ListItemText primary="BEV2 Support & Documentation" />
          </ListItemButton>
        </ListItem>

        <ListItem key="trans" sx={{padding:"5px 0"}}>
          <ListItemButton sx={{"&:hover": {background:'#333'}, padding:"5px"}} onClick={() => navigate("/")}>
            <ListItemText primary="Transmission Support & Documentation" />
          </ListItemButton>
        </ListItem>

        <ListItem key="vin" sx={{padding:"5px 0"}}>
          <ListItemButton sx={{"&:hover": {background:'#333'}, padding:"5px"}} onClick={() => navigate("/vinlookup")}>
            <ListItemText primary="VIN Lookup" />
          </ListItemButton>
        </ListItem>    

        <Divider sx={{background:"#555", marginTop:"20px"}}/>

        <ListItem key="close" sx={{padding:"5px 0"}}>
          <ListItemButton sx={{textAlign:"center"}}>
            <ListItemText primary="Close" sx={{color:"#999"}}/>
          </ListItemButton>
        </ListItem> 
      </List>  
      {isLoggedIn() &&
        <Stack flexGrow="1" justifyContent="flex-end" >
          <Button variant="contained" disableElevation sx={{ fontWeight: "bold", padding: "1em" }} onClick={handleLogout}>Sign Out</Button>
        </Stack>  
      }
    </Box>
  );

  return (
      <Drawer open={open} onClose={() => toggleDrawer()} PaperProps={{
        sx:{
          background:"#222",
          color:"#999"
        }
      }}>
        {DrawerList}
      </Drawer>
  );
}