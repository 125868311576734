import React, { useEffect, useRef } from 'react'
import { TextField, InputAdornment } from '@mui/material';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';


const StyledTextField = styled(TextField)({

    '& .MuiOutlinedInput-root': {
        height: '1em',
        padding: '1.75em 1em',
        fontSize:'1em',   
        borderRadius:'8px',
        border:'0',
        width:'350px'
    },
    '& fieldset': {
        border: 'none', 
        fontSize:'1em',       
    },
    '&:hover fieldset': {
        border: '0',
    },
    '&.Mui-focused fieldset': {
        border: '0',
    },
});


export default function SearchBar({placeholder, results, setSearchResults, disabled=false, searchKeys = []}){

    const ref = useRef(null);
    
    useEffect(() => {
        ref.current.value = "";
    }, [results]);

    const searchTable = (value) => {
        value = value.toString().toLowerCase();
        if(!value) return setSearchResults(results);   
        const keys = searchKeys.length > 0 ? searchKeys : Object.keys(results[0]);

        const filtered = results.filter((item) => 
            keys.some((key) => item[key]?.toString().toLowerCase().includes(value))
        );

        setSearchResults(filtered);
    }
    

    return (
       <StyledTextField ref={ref} className="" onChange={(e) => searchTable(e.target.value)} placeholder={placeholder} autoComplete="off" border="0" disabled={disabled} 
       InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}/>
    )   
} 
