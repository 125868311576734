import React, {useState, useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Snackbar,TablePagination,Box } from '@mui/material';
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function ScrollTable({columns, rows, rowClick = () => {return;}, rowClickParams = [], pagination = true}){
  const [copied, setCopied] = useState(false);
  const cl = columns.length;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  //Substract 20 for each column for padding
  //Substract 15 for scroll bar
  const cw = ((vw - (cl * 20) - 15) / cl);
  const cellWidth = Math.max(75, cw);
  const [pg, setpg] = useState(0); 
  const [rpg, setrpg] = useState(100);


  //Reset page when search results change for searching...

  useEffect(() => {
    if(pg > (rows.length / rpg)) setpg(0);
  }, [rows]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setCopied(false)
    }, 500)

    return () => {
      clearTimeout(timeId)
    }
}, [copied]);

  function handleChangePage(event, newpage) { 
    setpg(newpage); 
  } 

  function handleChangeRowsPerPage(event) { 
    setrpg(parseInt(event.target.value, 10)); 
    setpg(0); 
  } 

  return ( 
    <>   
      <TableContainer sx={{ height:"auto"}}>
          <Table stickyHeader aria-label="sticky table" sx={{ wordWrap:"break-word", }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.label}
                    align={column.align}
                    width={cellWidth}
                    sx={{
                      backgroundColor: "#181818",
                      fontSize:"1em",
                      fontWeight:"bold",
                      borderBottom:"1px solid #333",
                      padding:"10px",
                      textAlign:"center",
                      color:"#999"
                      
                    }}>
                    {column.label}
                    
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody >
              {rows.length > 0 
                ? //rows.map((row, index) => {
                  rows.slice(pg * rpg, pg * rpg + rpg).map((row, index) => ( 
                      row.divider          
                        ? <TableRow  sx={{background:row.background}}>

                            <TableCell colSpan={columns.length} sx={{color:row.color, textAlign:"center", fontWeight:"bold", padding:"7px"}}>
                                {row.text}
                            </TableCell>

                          </TableRow>
                      

                        : <TableRow key={`row${index}`} hover role="checkbox" tabIndex={-1} onClick={() => {
                          const params = [];
                          rowClickParams?.forEach((p, i) => { 
                            params.push(row[p]); 
                          });
                          rowClick(params);
                        }}>
                          {columns.map((column, index) => {
                            return (
                              <TableCell
                                key={column.dataKey}
                                align="center"
                                width={cellWidth}
                                sx={{fontSize:'1em', borderBottom:'1px solid #333', cursor:'pointer', padding:'15px', whiteSpace: 'pre-wrap', color:"#999"}}>
                                <CopyToClipboard  onCopy={() => setCopied(true)} text={row[column.dataKey]} style={{cursor:"pointer"}}>
                                  <span>{row[column.dataKey]}</span>
                                </CopyToClipboard>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                       
                  ))
                  : <TableRow>
                      <TableCell key="empty" colSpan={columns.length} sx={{fontSize:'1em', cursor:'pointer', padding:'20px', borderBottom:'0',textAlign:'center'}}>No results found.</TableCell>
                    </TableRow>
                }
            </TableBody>
          </Table>
      </TableContainer>

      {pagination &&
        <Box sx={{borderTop:"1px solid #333"}}>
          <TablePagination 
            rowsPerPageOptions={[100, 250, 500]} 
  
            component="div"
            count={rows.length} 
            rowsPerPage={rpg} 
            page={pg} 
            onPageChange={handleChangePage} 
            onRowsPerPageChange={handleChangeRowsPerPage} 
          /> 
        </Box>
      }

      <Snackbar open={copied} anchorOrigin ={{vertical:'bottom', horizontal:'center'}} autoHideDuration={1000} message="Copied!"/>  
    </>
  )
}